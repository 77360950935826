@font-face {
  font-family: 'Proxima Soft';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/ProximaSoft-Regular.woff') format('woff'),
    url('../assets/fonts/ProximaSoft-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima Soft';
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/ProximaSoft-RegularItalic.woff') format('woff'),
    url('../assets/fonts/ProximaSoft-RegularItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima Soft';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/ProximaSoft-Semibold.woff') format('woff'),
    url('../assets/fonts/ProximaSoft-Semibold.woff2') format('woff2');
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 16px;
  font-weight: normal;
  font-synthesis: none;
  font-feature-settings: 'kern' 1, 'liga' 1;
  line-height: 1.5;
}

html,
body,
#root,
#app {
  height: 100%;
}

button,
input,
textarea {
  appearance: none;
  border: none;
  background: none;
  outline: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

button {
  cursor: pointer;
  padding: 0;
  padding-bottom: 1px;
}

button::-moz-focus-inner {
  border: 0;
}

.Link {
  opacity: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  transition: opacity 200ms ease;
}

.Link:not(.Link--noFade):hover {
  opacity: 0.7;
}

.PillLink {
  border-radius: 3px;
  transition: background-color 200ms ease;
}

.supports-hover .PillLink:hover {
  background: rgba(0, 0, 0, 0.07);
}

.PillLink.PillLink:active {
  background: rgba(0, 0, 0, 0.15);
}

.Input {
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 1px inset;
  padding: 0 12px;
  width: 100%;
  height: 44px;
  line-height: 36px;
  transition: border-color 100ms ease, box-shadow 100ms ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Input:focus {
  border-color: #4494ff;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 1px inset,
    rgba(68, 148, 255, 0.1) 0px 0px 0px 3px;
}

code {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
}

.CodeBlock {
  background-color: rgba(255, 139, 139, 0.1);
}

.StatusBar {
  height: 20px;
}
