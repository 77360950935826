/**
 * Extensions to the styles generated by bin/build-css
 * Doing this manually for now, but hope to merge these back into hibiscss
 * once I'm done.
 */

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

.p-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.t--1 {
  top: -1px;
}
.t--2 {
  top: -2px;
}
.t-0 {
  top: 0;
}
.t-16 {
  top: 16px;
}
.b-0 {
  bottom: 0;
}
.b--1 {
  bottom: -1px;
}
.b-16 {
  bottom: 16px;
}
.l-0 {
  left: 0;
}
.l-8 {
  left: 8px;
}
.l-16 {
  left: 16px;
}
.r-0 {
  right: 0;
}
.r-16 {
  right: 16px;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-9 {
  z-index: 9;
}

.z-10 {
  z-index: 10;
}

.us-none {
  user-select: none;
}

.ud-none {
  -webkit-user-drag: none;
}

.to-ellipsis {
  text-overflow: ellipsis;
}

.ws-preWrap {
  white-space: pre-wrap;
}

.ws-noWrap {
  white-space: nowrap;
}

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ls-loose {
  letter-spacing: 3px;
}

.va-top {
  vertical-align: top;
}

.va-middle {
  vertical-align: middle;
}

.xg-0 {
  flex-grow: 0;
}

.xg-1 {
  flex-grow: 1;
}

.xs-0 {
  flex-shrink: 0;
}

.xs-1 {
  flex-shrink: 1;
}

.xjs-center {
  justify-self: center;
}

.xs-center {
  align-self: center;
}
.xs-baseline {
  align-self: baseline;
}
.xs-stretch {
  align-self: stretch;
}
.xs-start {
  align-self: flex-start;
}
.xs-end {
  align-self: flex-end;
}

.w-90p {
  width: 90%;
}

.mw-100 {
  max-width: 100px;
}

.mw-140 {
  max-width: 140px;
}

.w-100p {
  width: 100%;
}

.h-100p {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.mh-40vh {
  min-height: 40vh;
}

.mh-50vh {
  min-height: 50vh;
}

.mh-100vh {
  min-height: 100vh;
}

.mxh-40vh {
  max-height: 40vh;
}

.bs-cover {
  background-size: cover;
}

.bs-60p {
  background-size: 60%;
}

.bs-80p {
  background-size: 80%;
}

.bs-100p {
  background-size: 100%;
}

.e-1 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.e-2 {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.bc-transparent {
  border-color: transparent;
}

.bc-white {
  border-color: white;
}
.bc-pink {
  border-color: #ff7462;
}
.bc-black {
  border-color: #141414;
}
.bc-lightGray {
  border-color: #f0f0f0;
}
.bc-fadedBlack {
  border-color: rgba(12, 12, 12, 0.35);
}
.bc-gray0 {
  border-color: #f8f9fa;
}
.bc-gray1 {
  border-color: #e9ecef;
}
.bc-gray2 {
  border-color: #ced4da;
}
.bc-gray3 {
  border-color: #868e96;
}
.bc-gray4 {
  border-color: #212529;
}
.bc-coral {
  border-color: #ff7462;
}
.bc-fadedCoral {
  border-color: rgba(255, 106, 106, 0.5);
}

.ba-1 {
  border-width: 1px;
  border-style: solid;
}

.ba-2 {
  border-width: 2px;
  border-style: solid;
}

.ba-4 {
  border-width: 4px;
  border-style: solid;
}

.bt-0 {
  border-top-width: 0px;
  border-top-style: solid;
}

.bt-1 {
  border-top-width: 1px;
  border-top-style: solid;
}

.bt-2 {
  border-top-width: 2px;
  border-top-style: solid;
}

.bb-0 {
  border-bottom-width: 0px;
  border-bottom-style: solid;
}

.bb-1 {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.bb-2 {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.hover {
  transition: opacity 100ms ease;
}

.hover:hover {
  opacity: 0.5;
}

.hover-bg {
  border-radius: 3px;
  transition: background-color 200ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.15);
}

.supports-hover .hover-bg:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.hover-bg:active {
  background-color: rgba(0, 0, 0, 0.15);
}

.t-shrinkOnActive {
  transition: transform 200ms ease;
}

.t-shrinkOnActive:active {
  transform: scale(0.95);
}

@media only screen and (min-width: 767px) {
  .w-90p-m {
    width: 90%;
  }

  .w-100p-m {
    width: 100%;
  }

  .mw-100-m {
    max-width: 100px;
  }

  .mw-140-m {
    max-width: 140px;
  }

  .bs-cover-m {
    background-size: cover;
  }

  .bs-60p-m {
    background-size: 60%;
  }

  .bs-100p-m {
    background-size: 100%;
  }

  .bt-0-m {
    border-top-width: 0px;
    border-top-style: solid;
  }

  .bt-1-m {
    border-top-width: 1px;
    border-top-style: solid;
  }

  .bt-2-m {
    border-top-width: 2px;
    border-top-style: solid;
  }

  .bb-0-m {
    border-bottom-width: 0px;
    border-bottom-style: solid;
  }

  .bb-1-m {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .bb-2-m {
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }

  .bc-transparent-m {
    border-color: transparent;
  }
}
